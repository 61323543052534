import React, { useContext } from "react";
import "./Footer.css";
import Wave from "../../img/wave-mavi.png";
import phone from "../../img/phone-alt-solid.svg";
import email from "../../img/email.svg"
import { themeContext } from "../../Context"
// import {FaPhoneAlt} from "@font"

const Footer = () => {

  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="footer">
      <span style={{ backgroundColor: darkMode ? '#3F3D56' : '' }} className="wave"></span>
      {/* <img src={Wave} alt="" style={{ width: "100%" }} className="wave" /> */}
      <div className="f-content">
        <div className="row footerContainer">
          <div className="col-auto map">
            <iframe
              width="400"
              height="250"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              id="gmap_canvas"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11921.72832180836!2d26.575065!3d41.668011!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x109d3576e5d7aa6f!2sTrakya%20Teknopark!5e0!3m2!1str!2str!4v1619802045052!5m2!1str!2str"
            ></iframe>{" "}
          </div>
          <div className="col footerCol">
            <label style={{ color: darkMode ? 'var(--red)' : '' }} className="footerTitle">Address</label>
            <div class="w-100"></div>
            <label className="footerContent">
              <label className="footerContentTitle" style={{ fontWeight: "bold", }}>Trakya Teknopark</label>
              <br />
              Trakya Üniversitesi Ayşekadın Yerleşkesi, 1.Murat, Edirne
            </label>
          </div>
          <div className="col footerCol" >
            <label style={{ color: darkMode ? 'var(--red)' : '' }} className="footerTitle">Phone</label>
            <div class="w-100"></div>
            <a href="tel:+905425615838" style={{ color: "#fefefe" }}>
              {/* <FaPhoneAlt size="19" className="nav-linker" /> */}
              <img src={phone} style={{ width: "1.1rem" }} />
              <label className="footerContent" style={{ cursor: "pointer", paddingLeft: "0.2rem" }}>+905425615838</label>
            </a>
          </div>
          <div className="col footerCol" >
            <label style={{ color: darkMode ? 'var(--red)' : '' }} className="footerTitle">E-Mail</label>
            <div class="w-100"></div>
            <a href="mailto:info@iquality.com.tr">
              <img src={email} style={{ width: "1.1rem" }} />
              <label className="footerContent" style={{  cursor: "pointer", paddingLeft: "0.5rem" }}>
                info@iquality.com.tr
              </label>
            </a>
          </div>
        </div>
        <div className="f-icons">

        </div>
        <div class="footer-copyright" style={{ backgroundColor: darkMode ? '#3F3D56' : '' }}>
          <div class="footer-copyright-wrapper">
            <p class="footer-copyright-text">
              <a class="footer-copyright-link" href="#" target="_self">
                ©2022. | iQuality Danışmanlık Teknoloji LTD. ŞTİ.
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
