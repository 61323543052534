// import React, { useContext } from "react";
// import "./Portfolio.css";
// import { Swiper, SwiperSlide } from "swiper/react"
// import "swiper/css";
// import sampasLogo from "../../img/sampasLogo.png";
// import sodexoLogo from "../../img/sodexo-logo.png";
// import vodafoneLogo from "../../img/vodafone.jpg";
// import ziraatlogo from "../../img/ziraat.jpg";
// import darmaLogo from "../../img/darmaLogo.png";
// import { themeContext } from "../../Context";
// const Portfolio = () => {
//   const theme = useContext(themeContext);
//   const darkMode = theme.state.darkMode;
//   return (
//     <div className="portfolio" id="portfolio">
//       {/* heading */}
//       <span style={{ color: darkMode ? 'white' : '' }}>Our Customers</span>
//       <span>& Projects</span>
//       <spane style={{ paddingTop: "2rem" }}>
//         We have carried out projects with many international companies.
//         <br />
//         We can share our demos about test management and automation of Web and Mobile solutions.
//         <br />
//         In addition, we perform end-to-end test automations such as API and DB systems.


//       </spane>
//       {/* slider */}
//       <Swiper
//         spaceBetween={30}
//         slidesPerView={3}
//         grabCursor={true}
//         className="portfolio-slider"
//       >
//         <SwiperSlide>
//           <img style={{ height: "6.5rem" }} src={sampasLogo} alt="" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img style={{ height: "6.5rem" }} src={sodexoLogo} alt="" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img style={{ height: "6.5rem", width: "20rem" }} src={darmaLogo} alt="" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img style={{ height: "6.5rem" }} src={vodafoneLogo} alt="" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img style={{ height: "6.5rem", width: "20rem" }} src={ziraatlogo} alt="" />
//         </SwiperSlide>
//       </Swiper>
//     </div>
//   );
// };

// export default Portfolio;


import { Swiper, SwiperSlide } from "swiper/react";
import musteri1 from "../../img/sampasLogo.png";
import musteri2 from "../../img/sodexo-logo.png";
import musteri3 from "../../img/vodafone.jpg";
import musteri5 from "../../img/ziraat.jpg";
import musteri4 from "../../img/darmaLogo.png";
import "swiper/css";
import "./Portfolio.css";
import React, { useContext } from "react";
import { themeContext } from "../../Context";
const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="portfolio" id="Portfolio">
      <div className="portfolioContent">

      {/*heading*/}
      <span  style={{ color: darkMode ? 'white' : '' }}>Our Customers</span>
       <span >& Projects</span>
       <spane  style={{ paddingTop: "2rem", color: darkMode ? 'white' : '' }}>
         We have carried out projects with many international companies.
         <br />
         We can share our demos about test management and automation of Web and Mobile solutions.
         <br />
         In addition, we perform end-to-end test automations such as API and DB systems.
       </spane>
      </div>
      {/*slider*/}
      <Swiper
        spaceBetween={90}
        slidesPerView={3}
        grabCursor={true}
        className="portfolio-slider"
      >
        <SwiperSlide>
          <img src={musteri1} style={{ width: "12rem" }} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={musteri2} style={{ width: "12rem" }} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={musteri3} style={{ width: "12rem" }} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={musteri5} style={{ width: "12rem" }} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={musteri4} style={{ width: "12rem" }} alt="" />
        </SwiperSlide>
        <SwiperSlide> 
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Portfolio;
