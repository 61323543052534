import React, { useContext } from "react";
import "./Services.css";
import Card from "../Card/Card";
import seLogo from "../../img/seLogo.png";
import azureLogo from "../../img/azureLogo.png";
import appLogo from "../../img/appLogo.png";
import pythonLogo from "../../img/pythonLogo.png";
import cLogo from "../../img/c#Logo.png";
import jiraLogo from "../../img/jiraLogo.png";
import sapLogo from "../../img/sapLogo.jpg";
import { themeContext } from "../../Context";
import { motion } from "framer-motion"; 
const Services = () => {
  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // transition
  const transition = {
    duration: 1,
    type: "spring",
  };

  return (
    <div className="services" id="services">
      {/* left side */}
      <div className="awesome">
        {/* dark mode */}
        <span style={{ color: darkMode ? "white" : "" }}>Our</span>
        <span>services</span>
        <spane style={{ color: darkMode ? 'white' : '' }}>
          Create QA teams with 20+ experts
          <br />
          Manage 15+ projects
          <br />
          Manage 5+ remote teams with international customers
          <br />
          Use 10+ tools for QA projects
          <br />
          Create 3500+ Test Automation scenarios
          <br />
          Work on desktop, mobile, web applications
          <br />
          Work on SAP UI5 and SAP Logon, AWS and embedded device protocols
        </spane>
       
        <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
      </div>
      {/* right */}
      <div className="cards">
        {/* first card */}
        <motion.div
          initial={{ left: "2rem" }} 
          transition={transition}
        >
          <Card
            emoji={seLogo}
            heading={"Web App Test & Otomation"}
            detail={"Selenium"}
          />
        </motion.div>
        <motion.div
          initial={{ left: "18rem" }} 
          transition={transition}
        >
          <Card 
            emoji={azureLogo}
            heading={"Project Management"}
            detail={"Azure Devops"}
          />
        </motion.div>
        <motion.div
          initial={{ left: "34rem" }} 
          transition={transition}
        >
          <Card
            emoji={appLogo}
            heading={"App Test"}
            detail={"Appium"}
          />
        </motion.div>
        {/* second card */}
        <motion.div
          initial={{ left: "-14rem", top: "18rem" }} 
          transition={transition}
        >
          <Card
            emoji={pythonLogo}
            heading={"Object Oriented Programming"}
            detail={"Python"}
          />
        </motion.div>
        {/* 3rd */}
        <motion.div
          initial={{ top: "18rem", left: "2rem" }} 
          transition={transition}
        >
          <Card
            emoji={cLogo}
            heading={"Web Design & Mobile App"}
            detail={
              "C#"
            }
            color="rgba(252, 166, 31, 0.45)"
          />
        </motion.div>
        <motion.div
          initial={{ top: "18rem", left: "18rem" }} 
          transition={transition}
        >
          <Card
            emoji={jiraLogo}
            heading={"Report & Manage Work"}
            detail={
              "Jira"
            }
            color="rgba(252, 166, 31, 0.45)"
          />
        </motion.div>
        <motion.div
          initial={{ top: "18rem", left: "34rem" }} 
          transition={transition}
        >
          <Card
            emoji={sapLogo}
            heading={"Enterprise Resource Planning"}
            detail={
              "SAP ERP"
            }
            color="rgba(252, 166, 31, 0.45)"
          />
        </motion.div>
        <div
          className="blur s-blur2"
          style={{ background: "var(--purple)" }}
        ></div>
      </div>
    </div>
  );
};

export default Services;
