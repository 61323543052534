import React, { useContext } from "react";
import "./Works.css";
import ranorex from "../../img/ranorex.png";
import cypress from "../../img/cypress.png";
import iQulalitySmallLogo from "../../img/iQualitySmallLogo.png";
import seLogo from "../../img/seLogo.png";
import postman from "../../img/postman.png";
import microFocus from "../../img/microFocus.png";
import appLogo from "../../img/appLogo.png";
import jmeter from "../../img/jmeter.png";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import { Link } from 'react-scroll'
const Works = () => {
  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // transition
  return (
    <div className="works" id="works">
      {/* left side */}
      <div className="w-left">
        <div className="awesome">
          {/* dark Mode */}
          <span style={{ color: darkMode ? "white" : "" }}>
            Test Otomasyon
          </span>
          <span>Araçlarımız</span>
          <spane style={{ color: darkMode ? 'white' : '' }}>
            TestOps is a new methodology that promotes close collaboration between QA Development
            <br/>
             and Operation teams in order to reduce development cost and ensure quality.
            <br/>
            TestOps gives new responsibilities to QA Teams like maintain the
            <br/>
            automated testing environment and monitor all systems both in test and in production.
            
          </spane>
         
          <div
            className="blur s-blur1"
            style={{ background: "#ABF1FF94" }}
          ></div>
        </div>

        {/* right side */}
      </div>
      <div className="w-right">
        <motion.div
          initial={{ rotate: 45 }}
          whileInView={{ rotate: 0 }}
          viewport={{ margin: "-40px" }}
          transition={{ duration: 3.5, type: "spring" }}
          className="w-mainCircle"
        >
          <div className="w-secCircle">
            <img src={cypress} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={ranorex} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={iQulalitySmallLogo} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={jmeter} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={appLogo} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={microFocus} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={postman} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={seLogo} alt="" />
          </div>
        </motion.div>
        {/* background Circles */}
        <div className="w-backCircle blueCircle"></div>
        <div className="w-backCircle yellowCircle"></div>
      </div>
    </div>
  );
};

export default Works;
